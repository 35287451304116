import ReactDOM from 'react-dom';

// Locales
import 'moment/locale/ko';

// Fonts
import '@fontsource/noto-sans-kr';
import '@fontsource/gothic-a1';

// Styles
import 'css-reset/reset.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'styles/default.scss';

import ReactModal from 'react-modal';

const containerId = 'app';
const container = document.querySelector(`#${containerId}`);

if (!container) {
    throw new Error(`Container not found: #${containerId}`);
}

void import('./app').then(({ App }) => {
    ReactModal.setAppElement(container as HTMLElement);
    ReactDOM.render(<App />, container);
});
